import { CircularProgress } from "@mui/material";
import { FC, useEffect, useState } from "react";
import "./spinner.css";

export const Spinner: FC = () => {
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    setTimeout(() => {
      setErrorMessage("Chat service timeout. Please contact support!");
    }, 10000);
  });

  return (
    <div className="spinner">
      <CircularProgress />
      <p className="error-message">{errorMessage ?? ""}</p>
    </div>
  );
};
