import {
  createCognitiveServicesSpeechServicesPonyfillFactory,
  createDirectLine,
} from "botframework-webchat";
import {
  getSpeechTokenOptions,
  getTokenOptions,
  getTextBotTokenUrl,
  getSpeechBotTokenUrl,
  getRegion,
} from "../utils/constants";
import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";

type DirectLine = ReturnType<typeof createDirectLine> | null;

interface JwtPayload {
  region: string;
}

const useDirectLine = () => {
  const [speechToken, setSpeechToken] = useState<string | null>(null);
  const [directLine, setDirectLine] = useState<DirectLine>(null);

  useEffect(() => {
    (async () => {
      const response: Response = await fetch(
        getTextBotTokenUrl(),
        getTokenOptions,
      );
      const resJson = await response.json();

      const directLine = createDirectLine({ token: resJson.token });
      setDirectLine(directLine);
    })();

    (async () => {
      const response = await fetch(
        getSpeechBotTokenUrl(),
        getSpeechTokenOptions,
      );
      const resJson = await response.json();
      setSpeechToken(resJson.token);
    })();
  }, []);

  const getSpeechFactory = (authToken: string) =>
    createCognitiveServicesSpeechServicesPonyfillFactory({
      credentials: {
        region:
          getRegion !== "undefined" ? getRegion : getRegionFromToken(authToken),
        authorizationToken: authToken,
      },
    });

  const getRegionFromToken = (token: string) => {
    const decodedToken: JwtPayload = jwtDecode(token);

    return decodedToken.region;
  };

  return {
    directLine,
    speechToken,
    getSpeechFactory,
  };
};

export default useDirectLine;
