import { FC } from "react";
import ReactWebChat from "botframework-webchat";
import useDirectLine from "../../hooks/directLine";
import { getStyleOptions } from "../../utils/styling";
import "./bot.css";
import { Spinner } from "../spinner/spinner";

const BotWithSpeech: FC = () => {
  const { directLine, speechToken, getSpeechFactory } = useDirectLine();

  // Return the WebChat only when the DirectLine was instantiated
  return directLine && speechToken ? (
    <ReactWebChat
      className="bot"
      directLine={directLine}
      webSpeechPonyfillFactory={getSpeechFactory(speechToken)}
      styleOptions={getStyleOptions}
      locale="ro-RO"
    />
  ) : (
    <Spinner />
  );
};

export default BotWithSpeech;
