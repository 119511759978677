import { FC } from "react";
import "./intro.css";

interface IntroProps {
  showChat: (value: boolean) => void;
}

export const Intro: FC<IntroProps> = ({ showChat }) => {
  return (
    <div className="intro">
      <div className="content">
        <div className="top">
          <div className="header-logo">
            <img
              className="avatar"
              src="chatBotFemale.png"
              width="100px"
              height="100px"
            />
            <img src="admi-logo.png" alt="logo" />
          </div>
          <p>Bună!</p>
          <p>Sunt aici să răspund întrebărilor legate de admitere!</p>
        </div>

        <div className="card">
          <h1>Intreabă-mă orice!</h1>
          <p>
            Bună! Sunt AdmiAI, asistentul tău virtual pentru informații despre
            admiterea la UMFST Târgu Mureș. Cu plăcere îți stau la dispoziție
            oricând pentru a oferi răspunsuri la întrebările tale. Cum te pot
            ajuta astăzi?
          </p>

          <button onClick={() => showChat(true)}>Începe conversația</button>
        </div>
      </div>

      <img alt="logo" width="200px" height="50px" src="umfst-logo.png" />

      <p className="melonloop-ad">Powered by Melonloop</p>
    </div>
  );
};

export default Intro;
