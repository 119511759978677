import { FC, useState } from "react";
import "./App.css";
import Intro from "./components/intro/intro";
import { Collapse } from "@mui/material";
import BotWithSpeech from "./components/bot/bot";

const App: FC = () => {
  const [showChat, setShowChat] = useState(false);

  return (
    <div className="App">
      <Collapse in={!showChat}>
        <Intro showChat={setShowChat} />
      </Collapse>
      <div className={`${!showChat ? "hidden" : ""}`}>
        <div className={`app-header`}>
          <img src="admi-logo.png" alt="logo" className="logo" />
          <img src="umfst-logo.png" alt="image" />
        </div>
        <BotWithSpeech />
      </div>
    </div>
  );
};

export default App;
