export const getStyleOptions: Record<string, string | Record<string, string>> =
  {
    bubbleBackground: "rgba(0, 0, 255, .1)",
    bubbleFromUserBackground: "rgba(0, 255, 0, .1)",
    botAvatarInitials: "",
    botAvatarImage:
      "https://umfsttries.blob.core.windows.net/frontend/chatBotFemale.png",
    sendBoxButtonColor: "rgba(51, 221, 120, 0.8)",
    microphoneButtonColorOnDictate: "red",
    hideUploadButton: "true",
  } as const;
