export const getTokenOptions: Record<string, string | Record<string, string>> =
  {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  } as const;

export const getSpeechTokenOptions: Record<
  string,
  string | Record<string, string>
> = {
  method: "GET",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
} as const;

export const getTextBotTokenUrl: () => string = () =>
  `${process.env.REACT_APP_WEB_API_TEXT_TOKEN_ENDPOINT}`;

export const getSpeechBotTokenUrl: () => string = () =>
  `${process.env.REACT_APP_WEB_API_SPEECH_TOKEN_ENDPOINT}`;

export const getRegion: string = `${process.env.REACT_APP_REGION}`;
